<template>
  <div
    class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
  >
    <div class="flex h-16 shrink-0 items-center mt-8 mb-4">
      <img
        class="h-auto max-h-16"
        src="@/assets/images/logos/logo-comeen-workplace.svg"
        alt="Comeen Workplace logo"
      />
    </div>
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in navigation" :key="item.name">
              <SidebarItem
                v-if="!item.children"
                :name="item.name"
                :route="item.routeName"
                :icon="item.icon"
              />
              <SidebarGroup v-else :name="item.name" :icon="item.icon">
                <li v-for="subItem in item.children" :key="subItem.name">
                  <SidebarItem
                    :name="subItem.name"
                    :route="subItem.routeName"
                    :icon="subItem.icon"
                  />
                </li>
              </SidebarGroup>
            </li>
          </ul>
        </li>
        <li class="-mx-6 mt-auto" v-if="auth.user.value.isAdmin || auth.user.value.isDashboard || auth.user.value.isOwner">
          <DashboardLink />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { faBriefcase, faCalendar, faGear, faHouse, faUsers } from '@fortawesome/pro-regular-svg-icons'

import SidebarItem from '../default/SidebarItem.vue'
import SidebarGroup from '../default/SidebarGroup.vue'
import DashboardLink from './SidebarDashboardLink.vue'

const auth = useAuth()

const { t } = useI18n()

const route = useRoute()

const navigation = computed(() => {
  const navItems = []

  navItems.push({
    name: 'ui.common.home',
    routeName: 'index',
    icon: faHouse,
  })

  navItems.push({
    name: 'pages.schedule.title',
    routeName: 'schedule',
    icon: faCalendar,
  })

  navItems.push({
    name: 'pages.team.title',
    routeName: 'team',
    icon: faUsers,
  })

  navItems.push({
    name: 'pages.office.title',
    routeName: 'office',
    icon: faBriefcase,
  })

  navItems.push({
    name: 'resources.settings.name',
    routeName: 'settings',
    icon: faGear,
  })

  return navItems
})
</script>

<style lang="postcss" scoped>
.router-link-exact-active {
  @apply bg-gray-50;
}
</style>
